<template>
  <!--
    The view for the Home Office Report generation
  -->
  <HomeOfficeReport />
</template>

<script>
export default {
  name: "HomeOfficeReportView",
  components: {
    HomeOfficeReport: () => import('@/components/CustomerWorklog/HomeOfficeReport.vue')
  },
  metaInfo () {
    return {
      title: 'Home Office Report'
    }
  }
}
</script>
